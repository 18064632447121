.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Beispielhafte Höhe für die Container-Größe */
}

.shadow {
  position: relative;
  margin-left: 32%;
  width: 425px; /* Beispielhafte Breite für das Logo */
}

.animated-logo {
  opacity: 0;
  width: 100%; /* Volle Breite des Containers */
  transform: translateY(-20px);
  animation: fadeInBounce 2s ease infinite;
}

.shadow::before {
  content: '';
  position: absolute;
  bottom: -10px; /* Abstand von unten */
  left: 0;
  width: 100%;
  height: 10px; /* Höhe des Schattens */
  background: rgba(0, 0, 0, 0.3); /* Schattenfarbe */
  filter: blur(10px); /* Weichzeichnung für den Schatten */
}

@keyframes fadeInBounce {
  0%, 100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* "Bounce" Position */
  }
}