/* Aktualisierte CSS-Stile */
.modal-container {
  position: fixed;
  top: 0;
  left: 15%;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Semi-transparent background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  min-width: 950px;
  padding: 20px;
  position: relative;
  z-index: 1001;
  transition: transform 0.3s ease;
  transform: translateY(-50%);
}

/* Stil für den Schließen-Button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #888;
}

/* Stil für den Inhalt des Modals */
.modal-content {
  display: flex;
  flex-direction: row; /* Stack child elements vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
}

.modal-image {
  max-width: 400px; /* Set a maximum width for the image */
  margin-right: 20px;
}

.vertical-line {
  width: 2px;
  height: 120px;
  background-color: #0e0d0d; /* Color of the vertical line */
  margin-right: 20px; /* Adjust the margin to control spacing */
}

.modal-text {
  flex-grow: 1; /* Allow the text to expand and fill available space */
}

/* Stil für den Modal-Titel und die Beschreibung */
.modal h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.modal p {
  font-size: 16px;
  line-height: 1.4;
  color: #555;
}

.website-button {
  background-color: transparent; /* Transparent background */
  color: #000; /* Text color */
  padding: 10px 20px; /* Adjust padding as needed */
  border: 2px solid #353434; /* Black border */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px; /* Add some margin below the description */
}

.website-button:hover {
  background-color: #ffbb8b; /* Slight background color on hover */
}

.technology {
  margin-right: 8px; /* Adjust the margin as needed */
}

