.slider {
  position: absolute;
  top: 200px;
  left: 10%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 1000px;
  
  
}

.image-container {
  position: relative; /* Fügen Sie eine relative Position hinzu */
  width: 150%;
  max-width: 150%; /* Ändern Sie die max-width auf 100% */
  height: auto;
  border-radius: 10px;
  display: block;
  margin: 0 auto;

}

.image {
  width: 130%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 1.5); /* Hier wird ein Schatten hinzugefügt */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Fügt eine sanfte Übergangsanimation hinzu */
}


.right-arrow,
.left-arrow {
  position: absolute;
  top: 50%;
  font-size: 3rem;
  color: #ffffff;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  transform: translateY(-50%);
}

.left-arrow {
  left: 20px;
}

.right-arrow {
  right: -30%; /* Passen Sie den rechten Abstand an */
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}